<template>
  <div class="pembayaran">
    <div class="content" :class="success ? 'scroll-active' : ''">

      <template>
        <el-card class="card">
          <div slot="header">
            <span>Formulir Bukti Pembayaran</span>
          </div>
          <el-form :model="form" @submit.native.prevent="onSubmit" label-width="120px">
            <el-form-item label="Tanggal">
              <el-date-picker style="margin-left: 20px;width: 320px;" v-model="form.tanggal" type="datetime"
                placeholder="Pilih Tanggal" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Nama Pengirim">
              <el-input style="margin-left: 20px;width: 320px;" v-model="form.nama_rekening"></el-input>
            </el-form-item>
            <el-form-item label="Bank Pengirim">

              <el-select style="margin-left: 20px;width: 320px;" v-model="form.bank_rekening" filterable
                placeholder="Select">
                <el-option v-for="item in bankRekenings" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Nomor Rekening">
              <el-input style="margin-left: 20px;width: 320px;" v-model="form.nomor_rekening"></el-input>
              <div style="margin-left: 20px;width: 320px;">Kosongi jika tidak ada nomor rekening</div>
            </el-form-item>

            <el-form-item label="Bukti">
              <el-upload style="margin-left: 20px;width: 320px;" action="placeholder"
                :before-upload="handleBeforeUpload" list-type="picture" :on-preview="handlePreview"
                :file-list="fileList" :on-remove="handleRemove">
                <el-button slot="trigger" size="small" type="primary">Pilih Gambar</el-button>
                <div slot="tip" class="el-upload__tip">JPG/PNG file, max 2MB</div>
              </el-upload>
              <el-image v-if="previewImageUrl" :src="previewImageUrl" alt="Preview"
                style="margin-left:20px; margin-top: 10px; max-width: 300px;">
              </el-image>
            </el-form-item>
            <div class="form-container">
              <!-- <template>
        <el-popconfirm
          title="harap perhatikan, pastikan untuk mengecek data dengan benar dan dapat dipertanggungjawabkan!"
        > -->
              <el-alert
                title="jika mengalamai data error terus menerus saat mengirim data, silahkan refresh halaman terlebih dahulu!"
                type="info">
              </el-alert>
              <el-button type="primary" @click="centerDialogVisible = true">Kirim</el-button>
              
              <!-- </el-popconfirm>
        </template> -->
              <el-dialog title="Warning" :visible.sync="centerDialogVisible" width="100%" center>
              <span>harap perhatikan, pastikan untuk mengisi data dengan benar dan dapat dipertanggungjawabkan!</span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">Batal</el-button>
                <el-button type="primary" @click="onSubmit">Konfirmasi</el-button>
              </span>
            </el-dialog>

            </div>

          </el-form>
        </el-card>
      </template>
      
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tip from "@/components/tip.vue";
import lottie from "vue-lottie";
import success from "@/assets/animation/success.json";

export default {
  computed: {
    ...mapGetters({
      token: "getToken",
    }),
  },
  components: {
    Tip,
    lottie: lottie,
  },
  data() {
    return {
      form: {
        tanggal: '',
        tipe: '0',
        tahun_ajaran_id: '',
        semester: '',
        jumlah: '150000',
        cara_pembayaran: 'Transfer',
        bank_rekening: '',
        nomor_rekening: '',
        nama_rekening: '',
        bukti: null,
      },
      centerDialogVisible: false,
      fileList: [],
      previewImageUrl: '',
      bankRekenings: [
        "BANK BCA (BANK CENTRAL ASIA)",
        "BANK MANDIRI",
        "BANK BNI (BANK NEGARA INDONESIA)",
        "BANK SYARIAH INDONESIA (Eks BNI SYARIAH)",
        "BANK BRI (BANK RAKYAT INDONESIA)",
        "BANK SYARIAH INDONESIA (Eks BSM)",
        "BANK CIMB NIAGA",
        "BANK CIMB NIAGA SYARIAH",
        "BANK MUAMALAT",
        "BANK BTPN (BANK TABUNGAN PENSIUNAN NASIONAL)",
        "BANK BTPN SYARIAH",
        "JENIUS",
        "BANK SYARIAH INDONESIA (Eks BRI SYARIAH)",
        "BANK TABUNGAN NEGARA (BANK BTN)",
        "PERMATA BANK",
        "BANK DANAMON",
        "BANK BII MAYBANK",
        "BANK MEGA",
        "BANK SINARMAS",
        "BANK COMMONWEALTH",
        "BANK OCBC NISP",
        "BANK BUKOPIN",
        "BANK BUKOPIN SYARIAH",
        "BANK BCA SYARIAH",
        "BANK LIPPO",
        "CITIBANK",
        "INDOSAT DOMPETKU",
        "TELKOMSEL TCASH",
        "LINKAJA",
        "BANK DBS INDONESIA",
        "DIGIBANK",
        "SEABANK (Eks BANK KESEJAHTERAAN EKONOMI)",
        "BANK JAGO (Eks BANK ARTOS INDONESIA)",
        "BANK UOB INDONESIA",
        "TMRW by UOB INDONESIA",
        "BANK NEO COMMERCE (Akulaku)",
        "ALLO BANK (Eks BANK HARDA)",
        "BANK ALADIN (Eks BANK MAYBANK INDOCORP)",
        "BANK JABAR dan BANTEN (BJB)",
        "BANK DKI JAKARTA",
        "BPD DIY (YOGYAKARTA)",
        "BANK JATENG (JAWA TENGAH)",
        "BANK JATIM (JAWA BARAT)",
        "BPD JAMBI",
        "BPD ACEH",
        "BPD ACEH SYARIAH",
        "BANK SUMUT",
        "BANK NAGARI (BANK SUMBAR)",
        "BANK RIAU KEPRI",
        "BANK SUMSEL BABEL",
        "BANK LAMPUNG",
        "BANK KALSEL (BANK KALIMANTAN SELATAN)",
        "BANK KALBAR (BANK KALIMANTAN BARAT)",
        "BANK KALTIMTARA (BANK KALIMANTAN TIMUR DAN UTARA)",
        "BANK KALTENG (BANK KALIMANTAN TENGAH)",
        "BANK SULSELBAR (BANK SULAWESI SELATAN DAN BARAT)",
        "BANK SULUTGO (BANK SULAWESI UTARA DAN GORONTALO)",
        "BANK NTB",
        "BANK NTB SYARIAH",
        "BANK BPD BALI",
        "BANK NTT",
        "BANK MALUKU MALUT",
        "BANK PAPUA",
        "BANK BENGKULU",
        "BANK SULTENG (BANK SULAWESI TENGAH)",
        "BANK SULTRA",
        "BANK BPD BANTEN",
        "BANK EKSPOR INDONESIA",
        "BANK PANIN",
        "BANK PANIN DUBAI SYARIAH",
        "BANK ARTA NIAGA KENCANA",
        "BANK UOB INDONESIA (BANK BUANA INDONESIA)",
        "AMERICAN EXPRESS BANK LTD",
        "CITIBANK N.A.",
        "JP. MORGAN CHASE BANK, N.A.",
        "BANK OF AMERICA, N.A",
        "ING INDONESIA BANK",
        "BANK MULTICOR",
        "BANK ARTHA GRAHA INTERNASIONAL",
        "BANK CREDIT AGRICOLE INDOSUEZ",
        "THE BANGKOK BANK COMP. LTD",
        "THE HONGKONG & SHANGHAI B.C. (BANK HSBC)",
        "THE BANK OF TOKYO MITSUBISHI UFJ LTD",
        "BANK SUMITOMO MITSUI INDONESIA",
        "BANK RESONA PERDANIA",
        "BANK MIZUHO INDONESIA",
        "STANDARD CHARTERED BANK",
        "BANK ABN AMRO",
        "BANK KEPPEL TATLEE BUANA",
        "BANK CAPITAL INDONESIA",
        "BANK BNP PARIBAS INDONESIA",
        "KOREA EXCHANGE BANK DANAMON",
        "RABOBANK INTERNASIONAL INDONESIA",
        "BANK ANZ INDONESIA",
        "BANK WOORI SAUDARA",
        "BANK OF CHINA",
        "BANK BUMI ARTA",
        "BANK EKONOMI",
        "BANK ANTARDAERAH",
        "BANK HAGA",
        "BANK IFI",
        "BANK CENTURY",
        "BANK MAYAPADA",
        "BANK NUSANTARA PARAHYANGAN",
        "BANK SWADESI (BANK OF INDIA INDONESIA)",
        "BANK MESTIKA DHARMA",
        "BANK SHINHAN INDONESIA (BANK METRO EXPRESS)",
        "BANK MASPION INDONESIA",
        "BANK HAGAKITA",
        "BANK GANESHA",
        "BANK WINDU KENTJANA",
        "BANK ICBC INDONESIA (HALIM INDONESIA BANK)",
        "BANK HARMONI INTERNATIONAL",
        "BANK QNB KESAWAN (BANK QNB INDONESIA)",
        "BANK HIMPUNAN SAUDARA 1906",
        "BANK SWAGUNA",
        "BANK BISNIS INTERNASIONAL",
        "BANK SRI PARTHA",
        "BANK JASA JAKARTA",
        "BANK BINTANG MANUNGGAL",
        "BANK MNC INTERNASIONAL (BANK BUMIPUTERA)",
        "BANK YUDHA BHAKTI",
        "BANK MITRANIAGA",
        "BANK BRI AGRO NIAGA",
        "BANK SBI INDONESIA (BANK INDOMONEX)",
        "BANK ROYAL INDONESIA",
        "BANK NATIONAL NOBU (BANK ALFINDO)",
        "BANK MEGA SYARIAH",
        "BANK INA PERDANA",
        "BANK HARFA",
        "PRIMA MASTER BANK",
        "BANK PERSYARIKATAN INDONESIA",
        "BANK AKITA",
        "LIMAN INTERNATIONAL BANK",
        "ANGLOMAS INTERNASIONAL BANK",
        "BANK SAHABAT SAMPEORNA (BANK DIPO INTERNATIONAL)",
        "BANK PURBA DANARTA",
        "BANK MULTI ARTA SENTOSA",
        "BANK MAYORA INDONESIA",
        "BANK INDEX SELINDO",
        "BANK VICTORIA INTERNATIONAL",
        "BANK EKSEKUTIF",
        "CENTRATAMA NASIONAL BANK",
        "BANK FAMA INTERNASIONAL",
        "BANK MANDIRI TASPEN POS (BANK SINAR HARAPAN BALI)",
        "BANK AGRIS (BANK FINCONESIA)",
        "BANK MERINCORP",
        "BANK OCBC – INDONESIA",
        "BANK CTBC (CHINA TRUST) INDONESIA",
        "BANK BJB SYARIAH",
        "BPR KS (KARYAJATNIKA SEDAYA)"
      ] // Array statis bank_rekening
    };
  },

  methods: {
    handleBeforeUpload(file) {
      // Convert image to JPG/JPEG
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, img.width, img.height);
          canvas.toBlob((blob) => {
            this.form.bukti = new File([blob], `${file.name.split('.')[0]}.jpg`, { type: 'image/jpeg' });
            this.previewImageUrl = URL.createObjectURL(this.form.bukti);
            this.fileList = [this.form.bukti];
          }, 'image/jpeg');
        };
      };
      return false; // Prevent auto upload
    },
    handlePreview() {
      this.dialogVisible = true;
    },
    handleRemove() {
      this.form.bukti = null;
      this.fileList = [];
      this.previewImageUrl = '';
    },
    async onSubmit() {
      this.centerDialogVisible = false;
      this.loading = true;
      const formData = new FormData();
      for (const key in this.form) {
        if (key !== 'bukti') {
          formData.append(key, this.form[key]);
        }
      }

      if (this.form.bukti) {
        formData.append('bukti', this.form.bukti);
      }

      let url = process.env.VUE_APP_BASE_API + "api/v1/ppdb/pembayaranpendaftaran";
      let method = "post";
      if (this.id) {
        url =
          process.env.VUE_APP_BASE_API +
          `api/v1/ppdb/pembayaranpendaftaran`;
        method = "post";
      }

      this.$axios({
        method: method,
        url: url,
        data: formData,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => {

          this.$notify({
            title: "Success",
            message: "Kirim bukti pembayaran berhasil, tunggu verifikasi dari kami!",
            type: "success",
          });
          this.$router.push({ name: 'Riwayat' })

          this.loading = false;
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            message: "data bukti pengiriman tidak lengkap / tidak benar!",
            type: "error",
          });
          this.loading = false;
          console.log(err);
        });

    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  flex: 1;
  padding: $space * 2 4% $space * 2 4% !important;
  border: 1px solid darken(white, 10);

  @media #{$mq-xlarge} {
    padding: $space * 2 18% $space * 2 18% !important;
  }
}

.card {
  width: 100%;
}

.form-container {
  display: flex;
  justify-content: flex-end;
}
</style>
